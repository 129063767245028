import React, { useEffect, useState } from 'react'
import ReactNotification from 'react-notifications-component'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { getProfileDataApi } from './components/api/user'
import { createBrowserHistory } from 'history'
import 'react-notifications-component/dist/scss/notification.scss'
import 'animate.css/animate.compat.css'
import './scss/style.scss'

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
const Login = React.lazy(() => import('./components/pages/Login'))

function App() {
    const [userData, setUserData] = useState(null)
    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'false' ? false : true)
    const history = createBrowserHistory()

    useEffect(() => {
        getProfileDataApi(
            res => {
                if (res.data) {
                    res.data.permissionsTree?.dictionaries?.some(
                        item =>
                            item === 'location' ||
                            item === 'consoleStatus' ||
                            item === 'supplyStatus' ||
                            item === 'movingStatus' ||
                            item === 'repairStatus' ||
                            item === 'repairType'
                    )
                        ? setUserData({
                              ...res.data,
                              permissionsTree: {
                                  ...res.data.permissionsTree,
                                  dictionaries: ['*', ...res.data.permissionsTree.dictionaries]
                              }
                          })
                        : setUserData(res.data)
                } else {
                    setUserData({})
                }
            },
            err => setUserData({})
        )
    }, [])

    useEffect(() => {
        return history.listen(location => {
            console.log(`You changed the page to: ${location.pathname}`)
        })
    }, [history])

    useEffect(() => {
        localStorage.setItem('darkMode', darkMode)
    }, [darkMode])

    return (
        <BrowserRouter>
            <ReactNotification />
            <React.Suspense fallback={loading}>
                <div className={darkMode ? 'dark-mode-style' : ''}>
                    {userData ? (
                        <Switch>
                            {!userData.id && (
                                <Route
                                    exact
                                    path="/login"
                                    name="Login Page"
                                    render={props => <Login {...props} setUserData={setUserData} />}
                                />
                            )}
                            {userData.id ? (
                                <Route
                                    path="/"
                                    name="Home"
                                    render={props => (
                                        <TheLayout
                                            {...props}
                                            setUserData={setUserData}
                                            user={userData}
                                            permission={userData.permissionsTree}
                                            darkMode={darkMode}
                                            setDarkMode={setDarkMode}
                                        />
                                    )}
                                />
                            ) : (
                                <Redirect to="/login" />
                            )}
                        </Switch>
                    ) : (
                        <div className="spinner"></div>
                    )}
                </div>
            </React.Suspense>
        </BrowserRouter>
    )
}

export default App
